/* eslint-disable no-nested-ternary */

import intlTelInput from 'intl-tel-input';

window.addEventListener('DOMContentLoaded', () => {
  const phoneInput = document.querySelector('.js-form-phone-input');

  if (phoneInput) {
    const phoneInputField = phoneInput.querySelector('.js-form-phone-input-field');
    const phoneInputLabel = phoneInput.querySelector('.js-form-phone-input-label');
    const { host } = window.location;
    const initialCountry = host.includes('.fr') ? 'fr'
      : host.includes('.co.uk') ? 'gb'
        : host.includes('.de') ? 'de'
          : host.includes('.ch') ? 'ch'
            : host.includes('.at') ? 'at' : null;
    const isSubscriptionForm = document.querySelector('.user-subscription-form') !== null;
    const onlyCountries = isSubscriptionForm ? [] : ['at', 'be', 'ch', 'de', 'fr', 'gb'];
    const preferredCountries = isSubscriptionForm ? ['at', 'be', 'ch', 'de', 'fr', 'gb'] : [];
    const excludeCountries = JSON.parse(phoneInput.dataset.excludedCountries);

    const intlPhoneInput = intlTelInput(phoneInputField, {
      initialCountry,
      hiddenInput: phoneInputField.name,
      nationalMode: false,
      onlyCountries,
      preferredCountries,
      excludeCountries,
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/utils.js',
    });

    const formatIntlphoneInputField = () => {
      const currentText = intlPhoneInput.getNumber();
      if (typeof currentText === 'string') {
        phoneInputField.value = currentText;
      }
    };

    phoneInputField.addEventListener('keyup', formatIntlphoneInputField);
    phoneInputField.addEventListener('change', formatIntlphoneInputField);

    const setPhoneInputLabelClass = () => {
      phoneInputLabel.classList.toggle('form-phone-input__label--pinned', phoneInputField.value);
    };

    phoneInputField.addEventListener('input', setPhoneInputLabelClass);
    setPhoneInputLabelClass();
  }
});
